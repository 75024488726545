<template>
    <div class="cei">
        <div class="ceicontainer">
            <div class="ceicontainer_tit">CEI考核结果监控表</div>
            <div class="ceicontainer_top flex align-cen">
                <div class="num">220</div>
                <div class="text">CEI总项目总数(个)</div>
                 <div class="line"></div>
                 <div class="status">
                     <div class="st_1 flex align-cen">
                         <div class="st_text">好</div>
                         <div class="st_btn"></div>
                     </div>
                     <div class="st_1 flex align-cen">
                          <div class="st_text st_text1">不好</div>
                         <div class="st_btn st_btn1"></div>
                     </div>
                 </div>
                      <div class="ma-auto protarss flex align-cen">
                         <div class="kl">[</div>
                         <div class="wdt flex">
                             <div class="pro_btn">50%</div>
                          <div class="pro_btn pro_btn1">50%</div>
                         </div>
                         <div class="kl">]</div>
                     </div>
            </div>
            <div class="ceicontainer_comlu flex align-cen">
                <div class="all">全部项目</div>
                <div class="all1">待关注项目</div>
            </div>
            <div class="ceicontainer_bot">
                  <el-table
            class="table-h"
            :data="newArry"
            style="width: 100%；font-family: PingFang-SC-Regular;font-size:1vw"
            height="45vh"
            :row-class-name="tableRowClassName"
            fixed
          >
            <el-table-column prop="id" label="序号" align="center" width="100">
            </el-table-column>
            <el-table-column
              prop="project_name"
              label="项目名称"
              align="center"
             
            >
            </el-table-column>
            <el-table-column
              prop="project_start_day"
              align="center"
              label="项目启动时间"
            >
            </el-table-column>
            <el-table-column prop="engineer" align="center" label="项目经理">
            </el-table-column>
            <el-table-column prop="place" align="center" label="项目所在地">
            </el-table-column>
            <el-table-column
              prop="product_type_name"
              align="center"
              label="CEI"
            >
             </el-table-column>
             <el-table-column
              prop="product_phone"
              align="center"
              label="联系方式"
            >
            </el-table-column>
          </el-table>
          <el-pagination
            class="jiange"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          >
          </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'CEIStatistics',
    	data: () => {
            return {
            newArry:[
                {
                    id:1,
                    project_name:'山东海华650cfb',
                    project_start_day:'2019-12',
                    engineer:'赵楠',
                    place:'北京',
                    product_type_name:'D D A',
                    product_phone:'13080920872'
                }
            ],
                currentPage: 1, //初始页
        PageSize: 10, //每页的数据
      totalCount: 0,
                }
            },
             created(){
      this.getResultsData()
     
    },
    methods:{
          //前端自己分页
            getResultsData() {
                // this指向改一下
                let that = this;
                let list = that.newArry; //后端回来表格的数据
                //渲染的数据newArry赋值
                this.newArry= list.filter(
                (item, index) =>
                    index < that.currentPage * that.PageSize &&
                    index >= that.PageSize * (that.currentPage - 1)
                ); //根据页数显示相应的内容
                 console.log(this.newArry)
                this.totalCount = list.length;
            },
    }
}
</script>
<style lang="scss" scoped>
.cei{
    width: 100vw;
    background-color: #132735;
    height: 92vh;
    margin-left: -2.5vw;
    padding: 1px;
}
.ceicontainer{
    width: 82vw;
    height: 86vh;
    background-color: #071c2b;
	box-shadow: 0vh 1vh 2vh 0vh 
		rgba(83, 93, 113, 0.06);
	border-radius: 5px;
	opacity: 0.95;
    margin: 2.5vh auto;
    .ceicontainer_tit{
    font-family: FZLTHK--GBK1-0;
	font-size: 2vw;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0vw;
	color: #ffffff;
    height: 9vh;
    text-align: center;
    line-height: 9vh;
    }
    .ceicontainer_top{
    width: 78vw;
    height: 14vh;
	background-color: #004b6e20;
	border-radius: 5px;
    margin: 0 auto;
    .num{
        	font-family: PingFang-SC-Regular;
	font-size: 3vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 2vh;
	letter-spacing: 0vh;
	color: #ffffff;
    padding-left: 2vw;
    }
    .text{
        color: #6acafb;
        font-size: 1vw;
        padding-left: 2vw;
    }
    .line{
        width: 2px;
        height: 10vh;
         background:linear-gradient(244deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
         margin-left: 2vw;
    }
    .status{
        padding-left: 2vw;
        .st_1{
            .st_text{
                color: #6acafb;
                font-size: 1vw;
                width: 2vw;
                text-align: right;
            }
            .st_btn{
                width: 3vw;
                height: 2vh;
                background-color: #0e6de920;
                border: solid 1px #0e6de9;
                margin-left: 1vw;
            }
            .st_btn1{
                background-color: #00ffc620;
	            border: solid 1px #00ffc6;
            }
        }
    }
                .protarss{
                padding-right: 2vw;
                width: 45vw;
                height: 6vh;
                .kl{
                    font-size: 6vh;
                   color: #6acafb;
                }
                .wdt{
                    width: calc(43vw + 5px);
                    padding: 0 .5vw;
                }
                .pro_btn{
                    width: 50%;
                    height: 5vh;
                    background-color: #0e6de920;
	                border: solid 1px #0e6de9;
                    color: #0096ff;
                    text-shadow:3px 3px 3px 3px rgba(0, 0, 0, 0.88);;
            font-size: 3vh;
            text-align: center;
            line-height: 5vh;
            font-family: 'SourceHanSansCN-Medium'
                }
                .pro_btn1{
                    background-color: #00ffc620;
	            border: solid 1px #00ffc6;
                margin-left: 5px;
                color: #00ffc6;
                }
            }
    }
    .ceicontainer_comlu{
        height: 6vh;
         width: 78vw;
         margin: 0 auto;
        div{
            font-size: .8vw;
            color: #e5f6ff;
        }
        .all1{
            color: #00ffc6;
            padding-left: 2vw;
        }
    }
    .ceicontainer_bot{
         width: 78vw;
         margin: 0 auto;
         height: 53vh;
         margin-top: 2vh;

    }
}
 ::v-deep { 
                .jiange {
    margin-top: 1vh;
    background-color: #0c202f;
  }
    .table-h {
    height: 45vh;
    margin-left: 1vw;
    background-color: #0c202f;
    font-family: PingFang-SC-Regular;
    th {
      padding: 0 !important;
      height: 4vh;
      line-height: 4vh;
      background-color: #183f54;
      color: #6acafb;
      font-size: 2vh;
      font-family: PingFang-SC-Regular;
    }
    td {
      padding: 0 !important;
      height: 4vh;
      line-height: 4vh;
      background-color: #0c202f;
      color: #fff;
    }
  }
  .el-table td,
  .el-table th.is-leaf,
  .el-table--border,
  .el-table--group {
    border-color: #183f56;
    border-right: 1px solid #183f56;
  }
  .el-table__body {
border-left: 1px solid #183f56;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #183f56;
  }
  .el-table tbody tr:hover > td {
    background-color: #168db2;
  }
 }
</style>